<template>
  <div class="app-container">
    <Transition name="fade">
      <div v-if="showHashtag" class="background hashtag mid cormorant-upright">#IzzNina</div>
    </Transition>

    <Transition name="slideup">
      <router-view v-if="!showHashtag" class="main-router mid" />
    </Transition>

    <div class="song-section" @click="songPlayPause" :style="{ animationPlayState: showHashtag ? 'paused' : 'running',
      opacity: canPlay ? '1' : '0.5', pointerEvents: canPlay ? 'auto' : 'none' }">
      <Transition name="playpause">
        <img v-if="songIsPlaying" src="@/assets/images/PauseButton.png" alt="">
        <img v-else src="@/assets/images/PlayButton.png" alt="">
      </Transition>
      <audio :src="audioSource ? require(`./assets/songs/${audioSource}`) : ''" ref="audioEl"
        @emptied="audioEmptied" @ended="audioEnded"></audio>
    </div>

    <div v-if="isDev" class="dev-section">
      <button :disabled="testingLoading" @click="updateTesting">{{ testingLoading ? 'LOADING' : (isTesting == '1' ? 'DEV' : 'LIVE') }}</button>
    </div>

    <img class="background-image-top" :style="{
      transform: `rotate(20deg) translate(${!showHashtag ? '-50px, -80px' : '0px, 0px'})`,
      opacity: !showHashtag ? 0.75 : 1 }" src="./assets/images/BackgroundInitial1.png" alt="">
    <img class="background-image-bottom" :style="{
      transform: `rotate(200deg) translate(${!showHashtag ? '-50px, -80px' : '0px, 0px'})`,
      opacity: !showHashtag ? 0.75 : 1 }" src="./assets/images/BackgroundInitial1.png" alt="">

    <div v-if="isTesting" class="test-block">TEST MODE</div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { simulateAPI, api } from './js/apihelper';
import { useRoute } from 'vue-router';

//#region Data
const route = useRoute();

const showHashtag = ref(true);

const allSongs = ref([
  'A Thousand Years.mp3',
  'Afgan feat Rossa - Kamu Yang Kutunggu.mp3',
  'Aizat Amdan - Sampai Ke Hari Tua.mp3',
  'Ari Lasso feat Bunga Citra Lestari - Aku Dan Dirimu.mp3',
  'Cant Help Falling In Love - Live Session (Desmond Amos ft. DA Entertainment).mp3',
  'Cinta Luar Biasa - Andmesh Kamaleng (Saxophone Cover by Desmond Amos).mp3',
  'Rossa - Terlalu Cinta (Saxophone Cover by Dori Wirawan).mp3',
  'Shane Filan-Beautiful In White.mp3'
]);
const songIsPlaying = ref(false);
const audioEl = ref();
const audioSource = ref('');
const canPlay = ref(false);
const songIndex = ref(0);

const isDev = ref(false);
const isTesting = ref(false);
const testingLoading = ref(false);
//#endregion Data

//#region Methods
const songPlayPause = () => {
  songIsPlaying.value = !songIsPlaying.value;
  if (songIsPlaying.value) {
    audioEl.value.play();
  } else {
    audioEl.value.pause();
  }

  if (showHashtag.value) {
    showHashtag.value = false;
  }
}
const audioCanPlay = () => {
  canPlay.value = true;

  if (!showHashtag.value) {
    songPlayPause();
  }
}
const audioEmptied = () => {
  canPlay.value = false;

  if (songIsPlaying.value) {
    songPlayPause();
  }
}
const audioEnded = () => {
  audioSource.value = '';
  songIndex.value++;
  if (songIndex.value >= allSongs.value.length) {
    songIndex.value = 0;
  }

  audioSource.value = allSongs.value[songIndex.value];

  setTimeout(() => {
    audioEl.value.oncanplay = audioCanPlay();
  }, 500);
}
const updateTesting = async () => {
  testingLoading.value = true;
  let update = await api(`/Settings/UpdateSettings`, { setting: 'testing', value: isTesting.value == '1' ? '0' : '1' }); // For live
  // let update = await simulateAPI(2000, null); // Simulate for null
  // let update = await simulateAPI(2000, true); // Simulate for true
  // let update = await simulateAPI(2000, false); // Simulate for false
  testingLoading.value = false;

  if (update) {
    isTesting.value = isTesting.value == '1' ? '0' : '1';
  }
}
//#endregion Methods

onMounted(() => {
  testingLoading.value = true;
  songIndex.value = Math.floor(Math.random() * allSongs.value.length);
  audioSource.value = allSongs.value[songIndex.value];

  setTimeout(() => {
    audioEl.value.oncanplay = audioCanPlay();
  }, 1000);


  setTimeout(async () => {
    isTesting.value = (await api('/Settings/GetSettings', { setting: 'testing' })).value == 1; // For live testing mode
    // isTesting.value = await simulateAPI(1000, '0'); // Simulate getting false for test mode
    // isTesting.value = await simulateAPI(1000, '1'); // Simulate getting true for test mode
  
    testingLoading.value = false;

    isDev.value = route.params.who == 'dev' ? true : false;
  }, 1000);
})
</script>

<style scoped>
.app-container {
  width: 100vw;
  max-width: var(--maxwidth);
}
.background {
  position: fixed;
  width: 100vw;
  max-width: var(--maxwidth);
  height: 100vh;
  min-height: -webkit-fill-available;
  bottom: 0;
  transform: scale(1);
  transform-origin: 50% 100%;
  transition: var(--mainTrans);
  pointer-events: none;
}
.background-image-top {
  position: fixed;
  width: 90%;
  z-index: -99;
  top: -100px;
  left: -50px;
  transition: 3.5s ease-in-out;
}
.background-image-bottom {
  position: fixed;
  width: 90%;
  z-index: -99;
  bottom: -100px;
  right: -50px;
  transition: 3.5s ease-in-out;
}
.hashtag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4em;
  color: rgb(109,58,9);
}
.front {
  z-index: 101;
}
.back {
  z-index: 91;
}
.mid {
  z-index: 95;
}
.main-router {
  position: relative;
  transition: var(--mainTrans);
}
.test-block {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999999;
  opacity: 0.5;
}

.dev-section {
  position: absolute;
  height: 50px;
  width: 150px;
  top: calc(50% + 100px);
  left: calc(50% - 75px);
  z-index: 199;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dev-section > * {
  margin-top: 10px;
}
.dev-section > input {
  width: 100%;
  text-align: center;
}

.song-section {
  background-color: rgb(234, 153, 78);
  border-radius: 100%;
  height: 60px;
  width: 60px;
  max-height: 60px;
  max-width: 60px;
  position: fixed;
  z-index: 201;
  right: calc(50% - 30px);
  bottom: calc(50% - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: songSectionAnim 3s ease-out forwards;
  transition: 0.3s;
}
.song-section > img {
  position: absolute;
  object-fit: contain;
  height: 75%;
  width: 75%;
}
@keyframes songSectionAnim {
  to {
    bottom: 10px;
    right: 10px;
    transform: scale(0.5);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: var(--mainTrans);
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.slideup-enter-active, .slideup-leave-active {
  transition: 3s ease-out;
}
.slideup-enter-from, .slideup-leave-to {
  transform: translateY(100%);
}

.playpause-enter-active, .playpause-leave-active {
  transition: 0.3s;
}
.playpause-enter-from, .playpause-leave-to {
  transform: rotate(90deg);
  opacity: 0;
}
</style>

<style>
:root {
  --maxwidth: 750px;
  --mainTrans: 2s ease-in;
  --mainPadding: calc(25% + 75px) 10%;
}
body {
  margin: 0;
}
#app {
  display: flex;
  justify-content: center;
}

.error {
  color: indianred;
  font-size: 0.75em;
  text-align: center;
}
.success {
  color: limegreen;
  font-size: 0.75em;
  text-align: center;
}
input, select, textarea, .dropdown-main {
  box-sizing: border-box;
  width: calc(100%);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
}
input:focus, select:focus, textarea:focus {
  outline: none;
}
input:disabled, select:disabled, textarea:disabled {
  opacity: 0.5;
}

.main-button {
  padding: 5px 15px;
  background-color: rgb(255, 229, 229);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0.5em;
  border: 1px solid gray;
  transition: 0.3s;
  box-shadow: 5px 5px 5px gray;
}
.main-button * {
  margin: 0 !important;
}
.main-button:focus {
  outline: none;
}
.main-button:active {
  transform: scale(0.9);
  box-shadow: none;
}
button {
  font-family: 'Satisfy', cursive;
}

.gelasio {
  font-family: "Gelasio", serif;
  font-weight: 400;
  font-style: normal;
}
.truculenta {
  font-family: "Truculenta", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1.3em;
}
.beau-rivage {
  font-family: "Beau Rivage", cursive;
  font-weight: 400;
  font-style: normal;
}
.cormorant-upright {
  font-family: "Cormorant Upright", serif;
  font-weight: 400;
  font-style: normal;
}
.cormorant-upright-bold {
  font-family: "Cormorant Upright", serif;
  font-weight: 700;
  font-style: normal;
}
.yeseva-one {
  font-family: 'Yeseva One', serif;
}

.loader {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 3px dashed black;
  box-sizing: border-box;
  animation: button-loader-anim 1s ease-in-out infinite;
}
@keyframes button-loader-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>